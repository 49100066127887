import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import Button from "../components/commons/button/Button";

type Props = {
  redirect?: string;
  color?: "primary" | "success";
};

const NotFound: FC<Props> = ({ redirect, color = "primary" }) => {
  return (
    <>
      <div className="container mx-auto px-6 py-8 h-auto md:h-full flex flex-col">
        <div className="mx-auto flex flex-col justify-start md:justify-center items-center text-center h-full">
          <Image
            src={`/images/Illustration/404.svg`}
            alt="Storage"
            width={224}
            height={224}
          />
          <div className="text-title3 text-neutral-900 font-bold mt-[28px[">
            Ooops, Page Not Found
          </div>
          <p className="w-auto sm:w-[330px] text-neutral-600 text-regular-tight mt-[18px]">
            his page doesn’t exist or was removed!
            <br />
            we suggest you back to home
          </p>
          <div className="col-span-full mt-7">
            <Link href={`${redirect}`}>
              <Button color={color} type="submit" size="lg" className="w-fit">
                Back to Home
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
